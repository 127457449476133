import React from 'react'
import ContentHeader from '../components/contentHeader'
import ContentWrapper from '../components/contentWrapper'
import Teasers from '../components/teasers'
import TextBody from '../components/textBody'
import MailChimp from '../components/mailChimp'
import Seo from '../components/seo'

const Page = ({ doc, crumbs, teasers, showNewsLetterSignup }) => {
  return (
    <main>
      <Seo
        title={doc.metaTitle}
        description={doc.metaDescription}
        ogImage={doc.metaOgImage}
        ogType="article"
        url={doc.path}
      />
      <ContentHeader
        crumbs={crumbs}
        image={doc.mainImage}
        published={doc.published}
        heading={doc.heading}
        pageType={doc.pageType}
      />      
      <ContentWrapper>
        {doc.introduction && 
          <p className="introduction">{doc.introduction}</p>
        }
        <TextBody>{doc.body}</TextBody>
      </ContentWrapper>
      <ContentWrapper modifier="wide margin-top">
        <Teasers teasers={teasers} />
      </ContentWrapper>
      {showNewsLetterSignup && (
        <ContentWrapper modifier="wide light-bg--gray">
          <MailChimp />
        </ContentWrapper>
      )}
    </main>
  )
}

export default Page
