import React from 'react'
import { graphql } from 'gatsby'
import Page from './page'
import { renderRichText } from '../../contentful/renderRichText'
import linkResolver from '../../contentful/linkResolver'
import moment from 'moment';
import 'moment/locale/sv';


const PageContainer = ({ data, pageContext }) => {
  const { contentfulPage } = data
  const { breadcrumbs, locale } = pageContext

  moment.locale(contentfulPage.node_locale.slice(0,2))

  const page = {
    _doc: contentfulPage,
    path: linkResolver(contentfulPage),
    locale: contentfulPage.node_locale,
    heading: contentfulPage.heading,
    metaTitle: contentfulPage.metaTitle || contentfulPage.heading,
    metaDescription: contentfulPage.metaDescription,
    metaOgImage: contentfulPage.listImage
      ? contentfulPage.listImage.fluid
      : null,
    published: contentfulPage.published ? moment(contentfulPage.published).format('LL') : null,
    mainImage: contentfulPage.mainImage ? contentfulPage.mainImage.fluid : null,
    pageType: contentfulPage.pageType,
    introduction: contentfulPage.introduction ? contentfulPage.introduction.introduction : null,
    body: contentfulPage.body
      ? renderRichText(contentfulPage.body.json, locale)
      : null,
  }

  const showNewsLetterSignup =
    contentfulPage.pageType === 'News article' ||
    contentfulPage.pageType === 'Press release' ||
    contentfulPage.pageType === 'Blog post'

  return (
    <Page
      doc={page}
      crumbs={breadcrumbs}
      showNewsLetterSignup={showNewsLetterSignup}
    />
  )
}

export default PageContainer

export const pageQuery = graphql`
  query($locale: String!, $slug: String!) {
    contentfulPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      node_locale
      heading
      metaTitle
      metaDescription
      slug
      published
      pageType
      introduction {
        introduction
      }
      mainImage {
        fluid(maxWidth: 720, maxHeight: 680, quality: 80) {
          ...GatsbyContentfulFluid
        }
      }
      listImage {
        fluid(maxWidth: 1200, maxHeight: 627, quality: 80) {
          ...GatsbyContentfulFluid
        }
      }
      body {
        json
      }
    }
  }
`
