import React, { useContext } from 'react'
import ContentWrapper from '../contentWrapper'
import { LayoutContext } from '../../layout/index.js'
import Breadcrumbs from '../breadcrumbs'
import './contentHeader.scss'
import Img from 'gatsby-image'

function ContentHeader({ crumbs, image, published, heading }) {
  const { localization } = useContext(LayoutContext)

  return (
    <React.Fragment>
      {image && (
        <div className="content-header">
          <div className="content-header__title">
            <div>
              <h1 className="heading-1 heading-1--larger">{heading}</h1>
              <p className="content-header__meta">
                <strong>{localization.published}</strong>
                <br />
                <span className="meta">{published}</span>
              </p>
            </div>
          </div>
          <div className="content-header__image">
            <Img fluid={image} placeholderClassName="lazy" />
          </div>
        </div>
      )}
      {!image && (
        <ContentWrapper>
          <div className="content-header-simple">
          <Breadcrumbs crumbs={crumbs} currentPage={heading} />
            <h1 className="heading-1">{heading}</h1>
            {published && (
              <p className="content-header-simple__meta">
                <strong>{localization.published}</strong>
                <br />
                <span className="meta">{published}</span>
              </p>
            )}
          </div>
        </ContentWrapper>
      )}
    </React.Fragment>
  )
}

export default ContentHeader
