import React from 'react'
import { Link } from 'gatsby'
import { TiArrowRight } from 'react-icons/ti'

import './teaser.scss'

function Teaser({ title, link, linkText, text, modifier }) {

  if (!title) return null

  const teaserContent = (
    <div>
      <div className="teaser__image"></div>
      <div className="teaser__body">
        <h2 className="teaser__heading heading-2 heading-2--blue">{title}</h2>
        <p>{text}</p>
        <span className="teaser__link">
          {linkText === null ? 'Läs mer' : linkText}
          <TiArrowRight />
        </span>
      </div>
    </div>
  )

  const url = link

  return (
    <article className={`teaser teaser--${modifier}`}>
      {url && !url.includes('http') && <Link to={url}>{teaserContent}</Link>}
      {url && url.includes('http') && <a href={url} target="_blank" rel="noreferrer">{teaserContent}</a>}
      {!url && <div>{teaserContent}</div>}
    </article>
  )
}
export default Teaser
