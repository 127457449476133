
import React from 'react'
import Link from 'gatsby-link'
import { TiArrowRight } from 'react-icons/ti'
import './breadcrumbs.scss'

function Breadcrumbs({ crumbs, currentPage }) {

  if (!crumbs) return null
  let markup = crumbs.map((page, i) => {
    // if (i < crumbs.length - 1) {
    return (
      <React.Fragment key={i}>
        <Link to={page.path}>{page.pageName}</Link>
        {i < crumbs.length - 1 && <TiArrowRight />}
      </React.Fragment>
    )
    // } else {
    //   return <span key={i}>{page.pageName}</span>
    // }
  })
  return (
    <nav className="breadcrumbs">
      <Link to="/">Start</Link>
      {crumbs.length > 0 && <TiArrowRight />}
      {markup}
      {currentPage && (
        <React.Fragment>
          <TiArrowRight />
          <span>{currentPage}</span>
        </React.Fragment>
      )}
    </nav>
  )
}

export default Breadcrumbs



// import React from 'react'
// import Link from 'gatsby-link'
// import { TiArrowRight } from 'react-icons/ti'
// import './breadcrumbs.scss'

// function Breadcrumbs({ crumbs }) {
//   // if (crumbs.length < 2) return null
//   let markup = crumbs.map((page, i) => {
//     if (i < crumbs.length - 1) {
//       return (
//         <React.Fragment key={i}>
//           <Link to={page.path}>{page.pageName}</Link>
//           <TiArrowRight />
//         </React.Fragment>
//       )
//     } else {
//       return <span key={i}>{page.pageName}</span>
//     }
//   })
//   return (
//     <nav className="breadcrumbs">
//       <Link to="/">Start</Link>
//       <TiArrowRight />
//       {markup}
//     </nav>
//   )
// }

// export default Breadcrumbs
