import React from 'react'
import Helmet from 'react-helmet'
import { LayoutContext } from '../../layout'
import defaultOgImage from '../../../static/og-image.png'

function Seo({ title, description, ogType, ogImage, url }) {
  const { siteName, siteUrl, locale } = React.useContext(LayoutContext)
  return (
    <Helmet>
      <title>{`${title} - ${siteName}`}</title>
      <meta property="og:title" content={title} />
      {description && <meta name="description" content={description} />}
      {description && <meta property="og:description" content={description} />}
      <meta property="og:image" content={ogImage ? ogImage.src : defaultOgImage} />
      <meta property="og:type" content={ogType} />
      <meta property="og:url" content={`${siteUrl}${url}`} />
      <meta property="og:locale" content={locale} />
    </Helmet>
  )
}

export default Seo
