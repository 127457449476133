import Teaser from '../teaser'
import React from 'react'

import './teasers.scss'

function Teasers({ teasers, modifier }) {

if(!teasers || teasers.length === 0) return null 

  const markup = teasers.map((teaser, index) => {   
    return <Teaser modifier={modifier} key={index} {...teaser} />
  })

  return (
    <div className="teasers">
      {markup}
    </div>
  )
}
export default Teasers
